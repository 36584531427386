<script>
import { VueMaskDirective as mask } from 'v-mask'
import PrintA4 from "@/components/print/defaults/PrintA4"
import {extratoArrematacao} from '@/domain/leiloes/services/financeiro'

export default {
  components: {PrintA4},
  nome: 'ExtratoArrematacaoPrint',
  props: [
      'id',
      'lote'
  ],
  directives: {mask},
  data () {
    return {
      isLoading: false,
      template: null
    }
  },
  computed: {
  },
  mounted () {
  },
  activated () {
  },
  created () {
    this.isLoading = true
    extratoArrematacao(this.lote.id)
      .then(({data}) => {
        this.template = data.template
        this.isLoading = false
      })
      .catch(error => {
        this.alertApiError(error)
        this.isLoading = false
      })
  },
  deactivated () {
  },
  watch: {},
  methods: {
  }
}
</script>

<template>
 <print-a4 :is-loading="isLoading">
   <div v-if="template" v-html="template.template"></div>
 </print-a4>
</template>
